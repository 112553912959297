import React, { useRef, useState } from "react";
import CompanyModel from "../../../../interfaces/models/company.model";
import ServiceResult from "../../../../interfaces/service-result";
import api, { createCancelToken } from "../../../../services/api-client";
import apiErrorHandler from "../../../../services/api-error-handler";
import { toast } from "react-toastify";
import { CompaniesPutArgs } from "../../../../interfaces/args/partners-put.args";
import { SomenteNumeros } from "../../../../utils/somente-numeros";
import ViaCepModel from "../../../../interfaces/models/via-cep.model";
import useViaCep from "../../../../hooks/useViaCep";
import { useForm } from "react-hook-form";
import { Loading } from "../../../../components/loading";
import { FaShop } from "react-icons/fa6";
import clsx from "clsx";

interface Props {
  company: CompanyModel;
}

export const FormCompany = ({ company }: Props) => {
  const cancelToken = createCancelToken();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string | null>(
    company?.logoUrl || null
  );

  const [isEditing, setIsEditing] = useState(false); // Estado para controlar a edição

  const { FetchCep } = useViaCep();

  const inputFile = useRef<HTMLInputElement>(null);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    values: {
      razaoSocial: company?.legalName || "",
      nomeFantasia: company?.comercialName || "",
      nationalDocument: company?.nationalDocument || "",
      email: company?.email || "",
      telefone: company?.phone || "",
      addressZipCode: company?.addressZipCode || "",
      addressStreet: company?.addressStreet || "",
      addressNumber: company?.addressNumber || "",
      addressNeighborhood: company?.addressNeighborhood || "",
      addressQualifier: company?.addressQualifier || "",
      addressCity: company?.addressCity || "",
      addressState: company?.addressState || "",
    },
  });

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingImage(true);

    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();

      reader.onload = () => {
        const formData = new FormData();
        formData.append("file", file);

        api
          .put<ServiceResult<string>>("/companies/upload/logo", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            setProfileImage(data.result!);
          })
          .catch((err) => {
            apiErrorHandler(err);
            toast.error("Erro ao atualizar a imagem de perfil");
          })
          .finally(() => setLoading(false));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    inputFile.current?.click();
  };

  const atualizarDados = (formData: any) => {
    setLoading(true);

    const args: CompaniesPutArgs = {
      ...formData,
      addressZipCode: SomenteNumeros(formData.addressZipCode),
      phoneNumber: SomenteNumeros(formData.telefone),
    };

    api
      .put<ServiceResult<CompaniesPutArgs>>("/companies", args, {
        cancelToken: cancelToken.token,
      })
      .then(() => {
        setIsEditing(false);
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cep: string = watch("addressZipCode") || "";

  const fetchCep = async (): Promise<void> => {
    const cepNumerico = SomenteNumeros(cep);
    console.log("CEP numérico:", cepNumerico);
    // Verifica se o CEP tem 8 dígitos
    if (cepNumerico.length !== 8) {
      toast.error("CEP inválido! Por favor, insira um CEP com 8 dígitos.");
      return;
    }

    try {
      // Chama o serviço para buscar o CEP
      const viaCepResponse: ViaCepModel = await FetchCep(cepNumerico); // Verifique se o FetchCep está retornando o formato correto

      if (viaCepResponse.erro) {
        toast.error("CEP inválido! Não foi possível obter o endereço.");
        return;
      }

      // Preenche os campos com os dados retornados da API
      setValue("addressStreet", viaCepResponse.logradouro || ""); // Altere o nome do campo para 'addressStreet'
      setValue("addressNeighborhood", viaCepResponse.bairro || ""); // Altere o nome do campo para 'addressNeighborhood'
      setValue("addressCity", viaCepResponse.localidade || ""); // Altere o nome do campo para 'addressCity'
      setValue("addressState", viaCepResponse.uf || ""); // Altere o nome do campo para 'addressState'
      setValue("addressZipCode", cepNumerico); // Setando o CEP também para o campo

      // Limpa os erros de validação dos campos
      clearErrors("addressZipCode");
      clearErrors("addressStreet");
      clearErrors("addressNeighborhood");
      clearErrors("addressCity");
      clearErrors("addressState");
    } catch (error) {
      toast.error("Erro ao buscar o CEP. Tente novamente.");
    }
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-auto pr-md-0">
          <input
            type="file"
            ref={inputFile}
            className="d-none"
            accept="image/*"
            multiple={false}
            onChange={handleChangeImage}
          />

          {profileImage ? (
            <div
              className="rounded-circle overflow-hidden cursor-pointer img-retail ratio ratio-1x1"
              onClick={handleImageClick}
              style={{ width: 105, height: 105 }}
            >
              {loadingImage ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Loading />
                </div>
              ) : (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="w-100 h-100"
                  style={{ objectFit: "cover" }}
                />
              )}
            </div>
          ) : (
            <FaShop
              color="#143166"
              size="6rem"
              onClick={handleImageClick}
              cursor={"pointer"}
            />
          )}
        </div>

        <div className="col-md-6">
          <h1 className="label-primary m-0">{company?.comercialName}</h1>
          {/* <p className="label-primary m-0">Moda e vestuário</p> */}
          <p style={{ opacity: 0.6 }}>{company?.email}</p>
        </div>
      </div>

      <hr />
      <form onSubmit={handleSubmit(atualizarDados)}>
        {/* Razão social / Nome fantasia */}
        <div className="row mb-2 g-2">
          <div className="col-md-6">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="razaoSocial"
            >
              Razão Social
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px " }}
              id="razaoSocial"
              placeholder="Razão Social"
              disabled
              {...register("razaoSocial")}
            />
            {errors.razaoSocial?.message && (
              <p className="text-danger">
                {errors.razaoSocial.message as string}
              </p>
            )}
          </div>
          <div className="col-md-6">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="nomeFantasia"
            >
              Nome Fantasia
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px " }}
              id="nomeFantasia"
              placeholder="Nome Fantasia"
              disabled
              {...register("nomeFantasia")}
            />
            {errors.nomeFantasia?.message && (
              <p className="text-danger">
                {errors.nomeFantasia.message as string}
              </p>
            )}
          </div>
        </div>

        {/* National Document (CNPJ) */}
        <div className="row mb-2 g-2">
          <div className="col-md-4">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="nationalDocument"
            >
              Documento Nacional (CNPJ)
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px" }}
              id="nationalDocument"
              placeholder="Documento Nacional"
              disabled
              {...register("nationalDocument")}
            />
            {errors.nationalDocument?.message && (
              <p className="text-danger">
                {errors.nationalDocument.message as string}
              </p>
            )}
          </div>

          <div className="col-md-4">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="nationalDocument"
            >
              E-Mail
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px" }}
              id="nationalDocument"
              placeholder="E-Mail"
              disabled={!isEditing}
              {...register("email")}
            />
            {errors.nationalDocument?.message && (
              <p className="text-danger">{errors.email?.message as string}</p>
            )}
          </div>
          <div className="col-md-4">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="telefone"
            >
              Telefone
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px " }}
              id="telefone"
              placeholder="Telefone"
              disabled={!isEditing}
              {...register("telefone")}
            />
            {errors.telefone?.message && (
              <p className="text-danger">{errors.telefone.message as string}</p>
            )}
          </div>
        </div>

        {/* Endereço */}

        <div className="row mb-2 g-2">
          <div className="col-md-4">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="addressZipCode"
            >
              CEP
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px" }}
              id="addressZipCode"
              placeholder="CEP"
              disabled={!isEditing}
              {...register("addressZipCode")}
              onBlur={fetchCep}
            />

            {errors.addressZipCode?.message && (
              <p className="text-danger">
                {errors.addressZipCode.message as string}
              </p>
            )}
          </div>

          <div className="col-md-4">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="addressStreet"
            >
              Rua
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px" }}
              id="addressStreet"
              placeholder="Rua"
              disabled={!isEditing}
              {...register("addressStreet")}
            />
            {errors.addressStreet?.message && (
              <p className="text-danger">
                {errors.addressStreet.message as string}
              </p>
            )}
          </div>
          <div className="col-md-4">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="addressNumber"
            >
              Número
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px" }}
              id="addressNumber"
              placeholder="Número"
              disabled={!isEditing}
              {...register("addressNumber")}
            />
            {errors.addressNumber?.message && (
              <p className="text-danger">
                {errors.addressNumber.message as string}
              </p>
            )}
          </div>
          <div className="col-md-4">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="addressNeighborhood"
            >
              Bairro
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px" }}
              id="addressNeighborhood"
              placeholder="Bairro"
              disabled={!isEditing}
              {...register("addressNeighborhood")}
            />
            {errors.addressNeighborhood?.message && (
              <p className="text-danger">
                {errors.addressNeighborhood.message as string}
              </p>
            )}
          </div>

          <div className="col-md-8">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="addressQualifier"
            >
              Complemento
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px" }}
              id="addressQualifier"
              placeholder="Complemento"
              disabled={!isEditing}
              {...register("addressQualifier")}
            />
            {errors.addressQualifier?.message && (
              <p className="text-danger">
                {errors.addressQualifier.message as string}
              </p>
            )}
          </div>
        </div>

        <div className="row mb-2 g-2">
          <div className="col-md-3">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="addressCity"
            >
              Cidade
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px" }}
              id="addressCity"
              placeholder="Cidade"
              disabled={!isEditing}
              {...register("addressCity")}
            />
            {errors.addressCity?.message && (
              <p className="text-danger">
                {errors.addressCity.message as string}
              </p>
            )}
          </div>
          <div className="col-md-3">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="addressState"
            >
              Estado
            </label>
            <input
              type="text"
              className="form-control mt-2"
              style={{ height: "52px" }}
              id="addressState"
              placeholder="Estado"
              disabled={!isEditing}
              {...register("addressState")}
            />
            {errors.addressState?.message && (
              <p className="text-danger">
                {errors.addressState.message as string}
              </p>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className={clsx("btn", isEditing ? "btn-secondary" : "btn-primary")}
            onClick={toggleEdit}
          >
            {isEditing ? "Cancelar" : "Editar"}
          </button>
          {isEditing && (
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary ms-2"
            >
              {loading ? "Carregando..." : "Salvar"}
            </button>
          )}
        </div>
      </form>
    </>
  );
};
